import axiosPost from '../axiosPost';
import { Empleado, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantEmpleadoIns = async (arg: UserCredentialsType, data: Empleado) => {
    const body: AxiosPostBody = {
        "service": "MerchantEmpleadoIns",
        "params": {
            usuarioNroDeDocumento: data.usuarioNroDeDocumento,
            merchantUsuarioRolId: data.merchantUsuarioRolId,
            merchantId: data.merchantId,
            merchantUsuarioSucursalId: data.merchantUsuarioSucursalId,
            merchantUsuarioFecha: data.merchantUsuarioFecha,
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
            if(result?.status?.code === 0) {
                return [result.status]
            } else {
                return result.result;
            }
}