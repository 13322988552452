import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, List, ListItem,ListItemIcon } from '@mui/material';
// assets
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import IconTables from "../../assets/icons/icon-tables.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
// styles
import { ListDrawerStyles } from './ListDrawer.styles';
// const
import { Routes } from "../../consts/Routes-App";
// Recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials, userInfo } from "../../recoilState/GlobalState";

export default function ListDrawer({ setOpen }) {
    const classes = ListDrawerStyles();
    const setRoute = useSetRecoilState(routeInfo);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [merchantsOpen, setMerchantsOpen] = useState(false);
    const [cobrosOpen, setCobrosOpen] = useState(false);
    const info = useRecoilValue(userInfo);


    const handleClick = (route) => {
        setRoute(route);
        setOpen(false);
    };

    const handleMerchantChange = (merchant) => {
        sessionStorage.setItem('merchantId', merchant.merchantId);
        sessionStorage.setItem('merchantRZ', merchant.merchantRZ);
        sessionStorage.setItem('merchantCUIT', merchant.merchantCUIT);
        sessionStorage.setItem('rolCod', merchant.rolCod);
        setCredentials({ ...credentials, ...merchant });
        window.location.assign('/dashboard')
    };

    const merchants = JSON.parse(sessionStorage.getItem('merchants'));
    const items = [{name: "Dashboard", link: "dashboard", rol: ["MA", "MD"]}, {name: "Sucursales", link: "sucursales", rol: ["MA", "MD"]}, {name: "Empleados", link: "empleados", rol: ["MA", "MD"]}, {name: "Cajas", link: "cajas", rol: ["MA", "MD"]} , {name: "Pos", link: "pos", rol: ["ME", "MJ", "MA", "MD"]}]

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {items.map((item, index) => (
                item.rol.includes(credentials.rolCod) &&
                    <Link to={`/${item.link}`} className={classes.link} key={item.name}>
                        <ListItem  className={classes.itemList} onClick={() => handleClick(item)}>
                            <ListItemIcon>
                                <img className={classes.iconsDrawer} src={IconDashboard} alt="Tables" />
                            </ListItemIcon>
                            <span className={classes.textColor}>{item.name}</span>
                        </ListItem>
                    </Link>
            ))}
        </List>
    );
}
