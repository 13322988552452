import React, { useEffect, useState } from "react";
// styles
import classes from "./dashboard.module.scss";
// assets
import Ventas from '../../assets/icons/ventas.svg';
import PorCobrar from '../../assets/icons/por-cobrar.svg';
// components
import Card from "../../components/utils/cards/Card";
import Saldos from "../../components/utils/cards/Saldos";
import Solicitudes from "../../components/utils/cards/Solicitudes";
// Recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from '../../recoilState/GlobalState'
import { Routes } from "../../consts/Routes-App";
import { RNMerchantVentasDelDiaGet } from "../../services/hooli-services/Merchants/RNMerchantVentasDelDiaGet";
import { RNMerchantVentasPorAcreditarGet } from "../../services/hooli-services/Merchants/RNMerchantVentasPorAcreditarGet";
import { RNMerchantSaldoGet } from "../../services/hooli-services/Merchants/RNMerchantSaldoGet";
import { RNMerchantDatosGet } from "../../services/hooli-services/Merchants/RNMerchantDatosGet";

export default function Dashboard() {
    const [ventasHoy, setVentasHoy] = useState({ H: { monto: 0, operaciones: 0 }, C: { monto: 0, operaciones: 0 }, D: { monto: 0, operaciones: 0 }, B: { monto: 0, operaciones: 0 }, T: { monto: 0, operaciones: 0 } });
    const [ventasSemana, setVentasSemana] = useState({});
    const [cobrarHoy, setCobrarHoy] = useState({ H: { monto: 0, operaciones: 0 }, C: { monto: 0, operaciones: 0 }, D: { monto: 0, operaciones: 0 }, B: { monto: 0, operaciones: 0 }, T: { monto: 0, operaciones: 0 } });
    const [cobrarFuturo, setCobrarFuturo] = useState({});
    const [totales, setTotales] = useState({});
    const [ultimoMovimiento, setUltimoMovimiento] = useState({});
    const [cargando, setCargando] = useState(true);
    const [ventasDia, setVentasDia] = useState({});
    const [porAcreditar, setPorAcreditar] = useState({});
    const [data, setData] = useState({});
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    
    const ventasDelDiaGet = async() => {
        const responseDia = await RNMerchantVentasDelDiaGet(credentials);
        const formatDia = responseDia[0] && responseDia?.map(item => {
            return {
                montoTotal: item.RNMerchantMontoTotal,
                cantidadTotal: item.RNMerchantCantidadTotal,
                montoTotalArs: item.RNMerchantMontoARS,
                cantidadArs: item.RNMerchantCantidadARS,
                montoCredito: item.RNMerchantMontoCreditos,
                cantidadCredito: item.RNMerchantCantidadCreditos
            }
        })
        setVentasDia(formatDia)
    }

    const ventasPorAcreditarGet = async() => {
        const responseAcreditar = await RNMerchantVentasPorAcreditarGet(credentials);
        const formatAcreditar = responseAcreditar[0] && responseAcreditar?.map(item => {
            return {
                montoTotal: item.RNMerchantMontoPorAcreditar,
                cantidadTotal: item.RNMerchantCantidadPorAcreditar,
                montoTotalArs: item.RNMerchantMontoPorAcreditarARS,
                cantidadArs: item.RNMerchantCantidadPorAcreditarARS,
                montoCredito: item.RNMerchantMontoPorAcreditarCreditos,
                cantidadCredito: item.RNMerchantCantidadPorAcreditarCreditos
            }
        })
        setPorAcreditar(formatAcreditar)
    }

    const saldosGet = async() => {
        const responseSaldo = await RNMerchantSaldoGet(credentials);
        setTotales(responseSaldo)
    }

    const datosGet = async() => {
        const responseData = await RNMerchantDatosGet(credentials);
        if(responseData[0]?.errcode === 32411){
            setData({errMsg: responseData[0]?.errmsg})
        } else {
            setData(responseData)
        }
    }

    const getInfo = async() => {
        if(credentials.merchantId){
            Promise.all([ventasDelDiaGet(), ventasPorAcreditarGet(), saldosGet(), datosGet()]).then(() => {
                setCargando(false)
            })
        }
    }
    useEffect(() => {
        getInfo()
    }, [credentials]);
    useEffect(() => {
        setRoute(Routes[window.location.pathname.split('/')[1]])
    }, []);
    
    return (
        <main className={classes.main}>
            <div className={classes.container}>
                <div className={classes.cards}>
                    <Card
                        dataHoy={ventasHoy}
                        dataSerie={ventasSemana}
                        totales={ventasDia[0]}
                        title="Ventas del día"
                        link="/ventas"
                        image={Ventas}
                        cargando={cargando}
                    />
                    <Card
                        dataHoy={cobrarHoy}
                        dataSerie={cobrarFuturo}
                        totales={porAcreditar[0]}
                        title="Por acreditar"
                        link="/aCobrar"
                        image={PorCobrar}
                        cargando={cargando}
                        ultimoMovimiento={ultimoMovimiento}
                    />
                    <Saldos totales={totales[0]} cargando={cargando} />
                    <Solicitudes data={data} cargando={cargando} />
                </div>
            </div>
        </main>
    );
};
