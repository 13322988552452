import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantSucursalDel } from '../../../../services/hooli-services/Merchants/MerchantSucursalDel.ts'
import { MerchantSucursalGet } from '../../../../services/hooli-services/Merchants/MerchantSucursalGet.ts'

export default function DelSucursal({ updateList, servicio, closeModal, row }) {

const setSnackBarInfo = useSetRecoilState(snackbarData);
const credentials = useRecoilValue(userCredentials);

const [inRequest, setInRequest] = useState(false);

const formikRef = useRef();

const handleConnection = async (values) => {
    const response = await MerchantSucursalDel(
        credentials,
        values.merchantSucursalId,
    );
    console.log(response)
    if (response.length === 0) {
        formikRef.current.resetForm();
        updateList();
        closeModal();
        setSnackBarInfo({
            message: "Sucursal eliminada correctamente",
            severity: "success",
            open: true,
        });
    }
}

const getInfo = async (cred) => {
    const data = await MerchantSucursalGet(cred, cred.merchantId, row.merchantSucursalId)
    console.log(data)
    formikRef.current.setValues({
        ...data?.result[0],
        merchantSucursalNombre: data.result[0].merchantSucursalNombre,
        domicilioCalle: data?.result[0]?.domicilioCalle,
        domicilioCasa: data?.result[0]?.domicilioCasa,
        domicilioPiso: data?.result[0]?.domicilioPiso,
        domicilioUnidad: data?.result[0]?.domicilioUnidad,
        domicilioCPA: data?.result[0]?.domicilioCPA,
        merchantSucursalTelefono: data?.result[0]?.merchantSucursalTelefono,
        localidadId: data?.result[0]?.localidadNombre,
});
}
console.log(formikRef?.current?.values)
useEffect(()=> {
    getInfo(credentials)
},[])
    return (
        <Formik
        innerRef={formikRef}
        initialValues={{
            merchantSucursalNombre: "",
            domicilioCalle: "",
            domicilioCasa: "",
            domicilioPiso: "",
            domicilioUnidad: "",
            domicilioCPA: "",
            merchantSucursalTelefono: "",
            localidadId: "",
        }}
        validationSchema={
            yup.object({
                merchantSucursalNombre: yup.string("Debe ingresar un nombre").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar un nombre"),
                domicilioCPA: yup.string().max(8, "Debe tener 8 caracteres").min(8, "Debe tener 8 caracteres").required("Debes ingresar tu domicilioCPAigo postal"),
                domicilioCalle: yup.string("Debe ingresar una calle").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar una calle"),
                domicilioCasa: yup.string("Debe ingresar un numero").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                merchantSucursalTelefono: yup.string("Debe ingresar un telefono").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                localidadId: yup.string().required("Debe seleccionar una localidad"),
            })
        }
        onSubmit={(values) => handleConnection(values)}
    >
        <Form className={styles.inputs} noValidate>
            <div className={styles["column-sm"]}>
                <>
                    <FormikTextInput
                        disabled={true}
                        fullWidth={true}
                        name="merchantSucursalNombre"
                        labelText="Nombre"
                    />
                        <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="domicilioCalle"
                            labelText="calle"
                        />
                    <Box display={"flex"} width={"100%"} gap={4}>
                        <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="domicilioCasa"
                            labelText="Número"
                        />
                        <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="domicilioPiso"
                            labelText="Piso"
                        />
                        <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="domicilioUnidad"
                            labelText="Unidad"
                        />
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={4}>
                    <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="localidadId"
                            labelText="Localidad"
                        />
                        <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="domicilioCPA"
                            labelText="Código Postal"
                        />
                        <FormikTextInput
                            disabled={true}
                            fullWidth={true}
                            name="merchantSucursalTelefono"
                            labelText="Telefono"
                        />
                    </Box>
                </>
                {/* <Map lat={coords?.lat} lng={coords?.lng} setCoords={setCoords} /> */}
                <FormButton
                    inRequest={inRequest}
                    newFormData={{ button: "Eliminar" }}
                    onCloseModal={closeModal}
                />

                {(inRequest) && <Spinner />}
            </div>
        </Form>
    </Formik>
  )
}
