import React from 'react';
// Styles
import classes from './Solicitudes.module.scss';
// Skeleton
import 'react-loading-skeleton/dist/skeleton.css'
// Assets
import ArrowDown from '../../../assets/icons/arrow-down-red.svg';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

export default function Solicitudes({ data, cargando }) {
    return (
        <div className={classes.solicitudes}>
            <div className={classes.titleContainer}>
                <img className={classes.icon} src={ArrowDown} alt="Solicitudes" />
                <h4 className={classes.title}>Datos del negocio</h4>
            </div>
            <div className={classes.row}>
                {data?.errMsg && <div><p>{data?.errMsg}</p></div>}
                {data[0]?.merchantCVU && <>
                <div>
                    <h4>CVU:</h4>
                    <p>{data[0]?.merchantCVU}</p>
                    <ContentCopyRoundedIcon onClick={() => navigator.clipboard.writeText(data[0]?.merchantCVU)} />
                </div>
                <div>
                    <h4>Alias:</h4>
                    <p>{data[0]?.merchantAlias}</p>
                    <ContentCopyRoundedIcon onClick={() => navigator.clipboard.writeText(data[0]?.merchantAlias)} />
                </div>
                </>}
            </div>
        </div>
    )
};