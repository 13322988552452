import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type data = {
    monto: number
    merchantCajaId: number
    factura: string | null
    concepto: string | null
}

export const QR30AceptadorOperacionIns = async (arg: UserCredentialsType, data: data) => {
    const body: AxiosPostBody = {
        "service": "QR30AceptadorOperacionIns",
        "params": {
            posTransaccionMontoVenta: data.monto,
            merchantCajaId: data.merchantCajaId,
            posTransaccionNumeroFactura: data.factura,
            posTransaccionConcepto: data.concepto,
            posTransaccionOrigen: "P"
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
            if(result?.status?.code === 0) {
                return [result.status]
            } else {
                return [result.status];
            }
}