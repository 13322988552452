import React, { useEffect, useRef, useState } from "react";
// import { CircularIndeterminate, FormButton } from "components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import QRCode from "react-qr-code";
import { afipData, snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import FormButton from "../../../../components/Button/FormButton.jsx";
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import {QR30CodigoQRGet} from '../../../../services/hooli-services/Merchants/QrGet.ts'

export default function QrCajas({ user, updateList, servicio, closeModal, setterRowSelected }) {
  const setSnackBarInfo = useSetRecoilState(snackbarData);
  const credentials = useRecoilValue(userCredentials);
  const [qr, setQr] = useState(``);
  const qrRef = useRef();
  const getQr = async () => {
    const qrCode = await QR30CodigoQRGet(credentials, user.merchantCajaId)
    console.log(qrCode)
    setQr(qrCode[0].QR30CajaCodigoQR)
  }
  console.log(user)
  function imprimirElemento(elemento) {
      console.log(elemento)
      var ventana = window.open('', 'PRINT');
      ventana.document.write('<html><head><title>' + document.title + '</title>');
      ventana.document.write('<style>*{-webkit-print-color-adjust: exact;print-color-adjust: exact;}@page{margin: 0;}body{margin: 0; padding: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; background-color: #006E54}</style>');
      ventana.document.write('</head><body >');
      ventana.document.write(elemento.innerHTML);
      ventana.document.write('</body></html>');
      ventana.document.close();
      ventana.focus();
      ventana.print();
      ventana.close();
      closeModal()
      return true;
  }
  const printQr = () => {
      const element = document.getElementById("containerQr");
    //   const top = document.getElementById("containerTop");
    //   top.style.display = "flex";
      const green = document.getElementById("greenBg");
      green.style.display = "flex";
      const containerQr = document.getElementById("contQr");
      containerQr.style.padding = "42px"
      containerQr.style.backgroundColor = "#fff"
      containerQr.style.borderRadius = "42px";
    //   const textScan = document.getElementById("textScan");
    //   textScan.style.display = "flex";
    //   const bottom = document.getElementById("containerBottom");
    //   bottom.style.display = "flex";
      const qr = document.getElementById("qr");
      qr.style.width = "300px";
      qr.style.height = "300px";
      imprimirElemento(element);
  };

  useEffect(() => {
    getQr()
  })
    return (
      // <div></div>
      <div >
      <div>
          <div id={"containerQr"} ref={qrRef} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "100%" }}>
              {/* <div id={"containerTop"} style={{ fontFamily: "Lato, sans-serif", backgroundColor: "#2B3E4C", minHeight: "20px", width: "100%", padding: "32px 20px", display: "none", gap: "80px", justifyContent: "space-around", alignItems: "baseline", position: "relative", zIndex: "2" }}>
                  <p style={{ margin: "0", color: "#87B867", fontSize: "20px", lineHeight: "18px", maxWidth: "138px", alignSelf: "center" }}>La educación evoluciona para vos</p>
                  <img src={LogoNQN} alt="Logo Neuquen" /><img src={EscudoNQN} alt="Escudo Neuquen" style={{ width: "90px" }} />
              </div> */}
              <div id={"greenBg"} style={{ width: "100%", backgroundColor: "#006E54", display: "none", borderRadius: "80px 0px" }}></div>
              <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: "16px", paddingTop: "38px", justifyContent: "center", gap: "16px" }}>
                  <h3 style={{ fontFamily: "Lato, sans-serif", color: "#2B3E4C", textAlign: "center", fontSize: "32px", width: "fit-content", maxWidth: "80%", padding: "10px 24px", margin: "auto", display: "flex", justifyContent: "center", position: "relative", zIndex: "2", backgroundColor: "#fff", borderRadius: "16px" }}>{user.merchantCajaNombre}</h3>
                  {/* <div id="textScan" style={{ fontFamily: "Lato, sans-serif", color: "#fff", position: "relative", zIndex: "2", display: "none", flexDirection: "column", gap: "8px", marginLeft: "32px", marginBlock: "56px 24px" }}>
                      <h4 style={{ fontSize: "48px", margin: "0" }}>Escaneá el QR</h4>
                      <p style={{ fontSize: "32px", margin: "0" }}>Para realizar tu Firma Digital</p>
                  </div> */}
                  {qr ?
                      <div id={"contQr"} style={{ width: "fit-content", margin: "auto", display: "flex", justifyContent: "center", position: "relative", zIndex: "2" }}>
                          <QRCode value={qr} id="qr" style={{ width: "300px", height: "300px" }} />
                      </div>
                      : <Spinner />}
                      <h3 style={{ fontFamily: "Lato, sans-serif", color: "#2B3E4C", textAlign: "center", fontSize: "32px", width: "fit-content", maxWidth: "80%", padding: "10px 24px", margin: "auto", display: "flex", justifyContent: "center", position: "relative", zIndex: "2", backgroundColor: "#fff", borderRadius: "16px" }}>{user.merchantSucursalNombre}</h3>
              </div>
              {/* <div id="containerBottom" style={{ minHeight: "20px", width: "100%", paddingBlock: "32px", display: "none", justifyContent: "center", flexDirection: "column", alignItems: "start", gap: "16px", maxWidth: "575px" }}>
                  <div style={{ display: "flex", gap: "16px", fontSize: "32px", fontFamily: "Lato, sans-serif", alignItems: "center", width: "100%", borderBottom: "2px solid #2B3E4C", paddingBottom: "10px" }}>
                      <div style={{ flex: "1" }}>
                          <svg width="40" height="40" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M71.6238 95.4138H24.1192C10.9845 95.4138 0.275391 84.7047 0.275391 71.57V24.0196C0.275391 10.8849 10.9845 0.175781 24.1192 0.175781H71.6696C84.8043 0.175781 95.5134 10.8849 95.5134 24.0196V71.57C95.4676 84.7047 84.7585 95.4138 71.6238 95.4138ZM24.1192 6.76602C14.6 6.76602 6.86563 14.5003 6.86563 24.0196V71.57C6.86563 81.0892 14.6 88.8235 24.1192 88.8235H71.6696C81.1888 88.8235 88.9232 81.0892 88.9232 71.57V24.0196C88.9232 14.5003 81.1888 6.76602 71.6696 6.76602H24.1192Z" fill="#35404D" />
                              <path d="M68.1411 16.8867H25.625V77.8922H68.1411V16.8867Z" fill="#87B867" />
                              <path d="M64.9365 82.188H30.3378C26.2647 82.188 22.9238 78.9386 22.9238 74.9113V59.0306C22.9238 57.5204 24.1595 56.2847 25.6698 56.2847C27.18 56.2847 28.4157 57.5204 28.4157 59.0306V74.9113C28.4157 75.8723 29.331 76.696 30.3836 76.696H64.9823C66.0349 76.696 66.9502 75.8723 66.9502 74.9113V20.3587C66.9502 19.3977 66.0349 18.5739 64.9823 18.5739H30.3836C29.331 18.5739 28.4157 19.3977 28.4157 20.3587V36.2394C28.4157 37.7496 27.18 38.9853 25.6698 38.9853C24.1595 38.9853 22.9238 37.7496 22.9238 36.2394V20.3587C22.9238 16.3771 26.2647 13.082 30.3378 13.082H64.9365C69.0097 13.082 72.3506 16.3314 72.3506 20.3587V74.9113C72.3506 78.9386 69.0097 82.188 64.9365 82.188Z" fill="#35414D" />
                              <path d="M57.5184 44.6601L40.1275 27.2693C34.956 26.9489 33.5372 31.7085 35.9628 34.0883L45.6651 43.7906H16.4667C12.5766 47.223 14.9564 51.5708 18.3431 51.5708H45.6651L34.6356 62.6003C34.4068 66.1242 36.5578 67.9091 38.7087 67.9091C39.7156 67.9091 40.7224 67.543 41.4547 66.7649L57.5184 50.7012C58.3421 49.8774 58.7083 48.779 58.6625 47.6807C58.754 46.5823 58.3879 45.4839 57.5184 44.6601Z" fill="#35414D" />
                          </svg>
                      </div>
                      <p style={{ margin: "0", height: "fit-content", flex: "10" }}>Abrí la App MIA Neuquén</p>
                  </div>
                  <div style={{ display: "flex", gap: "16px", fontSize: "32px", fontFamily: "Lato, sans-serif", alignItems: "center", borderBottom: "2px solid #2B3E4C", paddingBottom: "10px" }}>
                      <div style={{ flex: "1" }}>
                          <svg width="40" height="54" viewBox="0 0 96 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M45.857 0.320312C20.5487 0.320312 0 20.8691 0 46.1774C0 70.6162 20.9148 104.117 33.4088 121.874C36.2463 125.992 40.9144 128.372 45.857 128.372C50.7997 128.372 55.4678 125.992 58.3052 121.874C70.7535 104.117 91.7141 70.6162 91.7141 46.1774C91.7141 20.9149 71.1654 0.320312 45.857 0.320312ZM52.7218 117.983C49.564 122.56 42.1958 122.56 38.9464 117.983C26.9101 100.867 6.77329 68.7856 6.77329 46.1774C6.77329 24.6219 24.3015 7.09366 45.857 7.09366C67.4126 7.09366 84.9408 24.6219 84.9408 46.1774C84.9408 68.8313 64.8039 100.913 52.7218 117.983Z" fill="#35404D" />
                              <path d="M45.8572 7.09766C24.3016 7.09766 6.77344 24.6259 6.77344 46.1814C6.77344 68.7896 26.9103 100.871 38.9466 117.987C42.1959 122.564 49.5642 122.564 52.722 117.987C64.8041 100.871 84.9409 68.7896 84.9409 46.1814C84.9409 24.6259 67.4127 7.09766 45.8572 7.09766ZM45.8572 67.7369C34.0039 67.7369 24.3016 58.0347 24.3016 46.1814C24.3016 34.3282 34.0039 24.6259 45.8572 24.6259C57.7104 24.6259 67.4127 34.3282 67.4127 46.1814C67.4127 58.0805 57.7104 67.7369 45.8572 67.7369Z" fill="#87B867" />
                              <path d="M46.5556 24.6328C34.7023 24.6328 25 34.3351 25 46.1883C25 58.0416 34.7023 67.7438 46.5556 67.7438C58.4088 67.7438 68.1111 58.0416 68.1111 46.1883C68.1111 34.3351 58.4088 24.6328 46.5556 24.6328ZM46.5556 60.9705C38.4093 60.9705 31.7733 54.3346 31.7733 46.1883C31.7733 38.0421 38.4093 31.406 46.5556 31.406C54.7018 31.406 61.3378 38.0421 61.3378 46.1883C61.3378 54.3346 54.656 60.9705 46.5556 60.9705Z" fill="#35404D" />
                          </svg>
                      </div>
                      <p style={{ margin: "0", height: "fit-content", flex: "10" }}><b>Estando dentro del establecimiento</b>, escaneá el QR</p>
                  </div>
                  <div style={{ display: "flex", gap: "16px", fontSize: "32px", fontFamily: "Lato, sans-serif", alignItems: "center", width: "100%" }}>
                      <div style={{ flex: "1" }}>
                          <svg width="40" height="36" viewBox="0 0 102 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M48.0959 96.7022C29.1032 95.7412 14.0006 87.5033 4.93905 69.7005C-4.16829 51.8062 -0.369747 29.2895 13.7718 15.0565C21.735 7.0475 31.3457 2.47105 42.5583 1.14385C54.32 -0.274879 64.9834 2.65407 74.7772 9.107C75.7383 9.74772 75.784 10.0681 74.9145 10.8461C73.4958 12.0818 72.0313 13.3175 70.6583 14.6447C69.9261 15.3312 69.3311 15.3769 68.4616 14.8735C63.9765 12.1733 59.1712 10.2512 53.9539 9.42739C46.3568 8.19173 39.0801 9.38151 32.0322 12.4478C23.8402 16.0175 17.2957 21.4179 13.0395 29.3811C10.4767 34.1865 9.05795 39.3579 8.46299 44.7582C7.68498 51.7146 8.3257 58.5795 11.4835 64.8952C17.433 76.9772 26.815 85.1692 40.2242 87.6863C60.0865 91.4391 80.086 80.2724 86.0813 59.2661C88.278 51.5775 88.0034 43.9346 85.5321 36.3375C85.0287 34.7357 85.0744 34.5068 86.3559 33.4085C87.6831 32.2186 89.056 30.983 90.3832 29.7473C91.207 29.015 91.5274 29.0608 91.985 30.0677C95.0055 37.1156 96.1497 44.438 95.509 52.0809C94.9598 58.4423 93.5868 64.529 90.7494 70.2496C86.6305 78.6705 80.2233 84.8946 72.3059 89.7458C66.5394 93.2697 60.3153 95.6495 53.542 96.336C51.9402 96.5191 50.2927 96.5649 48.0959 96.7022Z" fill="#35404D" />
                              <path d="M44.1141 56.7495C44.4345 56.2918 44.6633 56.0173 44.8464 55.697C50.7501 45.5828 57.5234 36.1094 65.4408 27.4597C72.9464 19.2677 81.2299 11.9451 90.5661 5.85827C94.0901 3.56999 98.3005 4.4854 100.451 7.96358C102.419 11.1214 101.413 15.1945 98.1174 17.3455C93.4036 20.4575 88.9643 23.9357 84.7081 27.6885C78.0721 33.5922 72.2141 40.1824 66.9053 47.3218C61.0474 55.1935 55.8301 63.523 51.6654 72.4015C50.7044 74.4152 49.2399 75.8797 46.9974 76.4289C43.7938 77.1611 41.3682 75.8337 39.446 73.3624C33.5881 65.7195 27.7301 58.0311 21.8721 50.3883C20.6364 48.7865 19.5838 47.1845 19.7669 45.0335C19.9957 42.1503 21.2771 39.9536 24.0231 38.8095C26.6317 37.7111 29.5607 38.2602 31.4371 40.4112C33.7254 43.1113 35.8306 45.9946 38.0273 48.7863C39.8579 51.1661 41.6886 53.5917 43.5192 55.9715C43.6107 56.2004 43.7938 56.4291 44.1141 56.7495Z" fill="#87B867" />
                          </svg>
                      </div>
                      <p style={{ margin: "0", height: "fit-content", flex: "10" }}><b>¡Listo!</b> Ya tenés tu Firma Digital</p>
                  </div>
              </div> */}
          </div>
      </div>
      <FormButton
          // inRequest={inRequest || isLoading}
          onClick={printQr}
          newFormData={{ button: "Imprimir" }}
          onCloseModal={closeModal}
      />
  </div>
  )
}
