import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { CajaType, UserCredentialsType } from '../../../types/types';

export const RNMerchantCobroGet = async (arg: UserCredentialsType, data:{merchantSucursalId: number|null, merchantCajaId: number|null}) => {
    const body: AxiosPostBody = {
        "service": "RNMerchantCobroGet",
        "params": {
            "merchantCajaId": data.merchantCajaId,
            "merchantSucursalId": data.merchantSucursalId
        }
    }

    const result = await axiosPost<CajaType>('Y', body, arg);
    return result
}