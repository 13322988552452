import { DataGridPro, LicenseInfo, esES } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import classes from "../XGrid/XGrid.module.scss";
const LICENSE_KEY = "eaa04298c880ee5a01c3c1f8ecfc8314Tz04ODc1OSxFPTE3NDUwOTA4NjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
LicenseInfo.setLicenseKey(LICENSE_KEY);


export default function XGridServer({loading=false, list, setNumberPage, rowSelect, setRow, columns, rowsCount, updateList = async () => { },
onPageChange = () => { },
onPageSizeChange = () => { },
onNumberOfPagesChange = () => { }, pageSize, setPageSize}) {
  const [rowCount, setRowCount] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const handleProxPage = (newPage) => {
    setPage(newPage.page +1)
    setNumberPage(newPage.page +1)
  }

  useEffect(() => {
    setRowCount(rowsCount)
  }, [rowsCount])
  // useEffect(() => {
  //   let element = document?.getElementsByClassName("css-204u17-MuiDataGrid-main")[0]?.lastChild
  //   element?.setAttribute("style", "display:none;")
  // }, [])
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <DataGridPro
        style={{minHeight: "200px"}}
        rows={list ?? []}
        columns={columns}
        loading={loading}
        // rowCount={rowCount ?? list.length}
        count={rowCount ?? list.length}
        pagination
        className={classes.root}
        // page={page}
        disableColumnMenu
        onRowClick={(data) => {
          if (data.row.id === rowSelect?.row.id) {
            console.log(data.row.id,rowSelect)
            setRow({ status: false, row: {} })
            return;
          }
          setRow({ status: true, row: data.row })
        }}
        // pageSize={pageSize || 5}
        autoPageSize
        // disabled
        selectionModel={rowSelect?.row.id || []}
        // paginationMode="server"
        onPaginationModelChange={(newPage) => {
          setPage(newPage);
          onPageChange(newPage);
          handleProxPage(newPage)
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          onPageSizeChange(newPageSize);
        }}
        onStateChange={(state) => {
          const newNumberOfPages = state.pagination.pageCount + 1;
          if(pageSize === 0 || pageSize === state?.pagination?.paginationModel?.pageSize){
            onPageSizeChange(state?.pagination?.paginationModel?.pageSize)
          }
          if (newNumberOfPages !== numberOfPages) {
            setNumberOfPages(newNumberOfPages);
            onNumberOfPagesChange(newNumberOfPages);
          }
        }}
        localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: "Sin datos" }}
      />
    </div>
  );
}