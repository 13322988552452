import React, { useState } from 'react';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';

export function UltimoBalance({nextStep,setStepDocumentos}) {
    const [file,setFile] = useState(null)
    const [checked, setChecked] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const recoilAfipData = useRecoilValue(afipData);
    const [comercio,setDataComercios] = useRecoilState(comercioData);
    const [disabledBtn, setDisabledBtn] = useState(false)
    const urlDoc = async() => {
      setDisabledBtn(true)
      // console.log(recoilAfipData.cuit.replaceAll('-', ''))
      const url = await UrlDocumentosGet(credentials, `${recoilAfipData.cuit.replaceAll('-', '')}_UB.pdf`, "UB" ,recoilAfipData.cuit);
      await sendFile(url.result[0].url)
      setDisabledBtn(false)
    }
    
    const sendFile = async (url) => {
      // console.log(file[0]) 
      await DocumentosPut(url, file[0])
    }

    const handleSubmit = async()=> {
      if(file !== null){
        await urlDoc();
        setDataComercios({...comercio, documentosBalanceCantidad: 1});
      };
      if(file === null && checked === true){
        setDataComercios({...comercio, documentosBalanceCantidad: 0});
      }
      setStepDocumentos({
        step:9, 
        title: "Acta de aprobación de balance",
        subtitle: "Subí tu Acta de aprobación de balance"
      })
    }
  return (
   <> 
    <DropZone file={file} setFile={setFile} optional={"No tengo balance"} checked={checked} setChecked={setChecked}/>
    <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={!file && !checked || disabledBtn}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepDocumentos({
           step:2, 
           title: "Constancia de Inscripción de Ingresos Brutos",
           subtitle: "Podés descargar tu constancia de inscripción desde el sitio web de la ARCA"
      })}/>
        </Box>
   </>
        
    
  )
}

 
