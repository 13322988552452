import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const RNMerchantSaldoGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        service: "RNMerchantSaldoGet",
        params: {
            merchantId: arg.merchantId
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
}