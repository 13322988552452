import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    status: 'A' | 'C'
    merchantCajaId: number,
    merchantSucursalId: number
};

export const merchantUsuarioCajaIns = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MerchantUsuarioCajaIns",
        "params": {
            "merchantCajaId": arg.merchantCajaId,
            "merchantSucursalId": arg.merchantSucursalId,
            "merchantUsuarioCajaStatus": arg.status,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result
    } catch (err) {
        console.log(err)
    }
}