import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import Map from '../../../../components/map/index.jsx'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { afipData, snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { QR30AceptadorOperacionIns } from '../../../../services/hooli-services/Merchants/QR30AceptadorOperacionIns.ts'
import { QR30AceptadorOperacionDel } from '../../../../services/hooli-services/Merchants/QR30AceptadorOperacionDel.ts'
import { QR30AceptadorOperacionGet } from '../../../../services/hooli-services/Merchants/QR30AceptadorOperacionGet.ts'
import { LocalidadGet } from '../../../../services/hooli-services/Merchants/LocalidadGet.ts'
import { ProvinciaGet } from '../../../../services/hooli-services/Provincias/ProvinciasGet.ts'

export default function AddCobro({ cajaId, updateList, servicio, closeModal }) {
    const [posId, setPosId] = useState(null)
    const [intentos, setIntentos] = useState(0)
    const credentials = useRecoilValue(userCredentials);

const setSnackBarInfo = useSetRecoilState(snackbarData);
let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
const [inRequest, setInRequest] = useState(false);

const formikRef = useRef();

const pagoConsulta = () => {
    if (intentos < 15){
        setTimeout(async () => {
            if(posId){
                setIntentos(intentos + 1);
                const response = await QR30AceptadorOperacionGet(credentials, Number(posId));
                if(response[0].POSTransaccionEstado === "A" || response[0].POSTransaccionEstado === "C") {
                    setPosId(null);
                    setSnackBarInfo({
                        message: "Cobro realizado",
                        severity: "success",
                        open: true,
                    });
                    setInRequest(false);
                    updateList();
                    closeModal();
                    return
                } else if(response[0].POSTransaccionEstado === "R" || response[0].POSTransaccionEstado === "X" || response[0].POSTransaccionEstado === "Z") {
                    setSnackBarInfo({
                        message: "Cobro fallido",
                        severity: "warning",
                        open: true,
                    });
                    setPosId(null);
                    setInRequest(false);
                    closeModal()
                    
                }
            }
        }, 3000);
    }
    if(intentos === 15){
        handleCancel()
        setPosId(null);
        setInRequest(false);
    }
}

const handleCancel = async() => {
    if(posId){
        const response = await QR30AceptadorOperacionDel(credentials, Number(posId));
        setPosId(null);
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Cobro cancelado",
                severity: "warning",
                open: true,
            });
        }
        setIntentos(0);
        setInRequest(false);
        closeModal()
        updateList();
    } else {
        updateList();
        closeModal()
    }
}

const handleConnection = async (values) => {
    const response = await QR30AceptadorOperacionIns(
        credentials,
        {monto: values.monto, merchantCajaId: cajaId, concepto: values.concepto, factura: values.nroFactura},
    );
    setPosId(response[0].id);
    pagoConsulta()
    setInRequest(true)
}

useEffect(() => {
    if (posId) {
        pagoConsulta()
    }
}, [posId, intentos])
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                monto: "",
                nroFactura: "",
                concepto: ""
            }}
            validationSchema={
                yup.object({
                    monto: yup.number("Debe ser un numero").test(
                        "is-decimal",
                        "Debe ingresar maximo dos decimales",
                        (val) => {
                            if (val != undefined) {
                            return patternTwoDigisAfterComma.test(val);
                            }
                            return true;
                        }
                        ).min(0.01, "Debe ser mayor a 0").typeError("Debe ser un numero con separador decimal punto").required("Debes ingresar un monto"),
                })
            }
            onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                            <FormikTextInput
                                fullWidth={true}
                                name="monto"
                                labelText="Monto"
                                disabled={inRequest}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="nroFactura"
                                labelText="Número de factura"
                                disabled={inRequest}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="concepto"
                                labelText="Concepto"
                                disabled={inRequest}
                            />
                        {inRequest && <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "16px", gap: "16px"}}><p style={{fontSize: "20px"}}><b>Pedile a tu cliente que lea el QR</b></p><Spinner /></div>}
                    <FormButton
                        inRequest={inRequest}
                        disabledCancel={false}
                        newFormData={{ button: "Cobrar" }}
                        onCloseModal={closeModal}
                        onSecondaryClick={handleCancel}
                    />
                </div>
            </Form>
        </Formik>
    );
}
