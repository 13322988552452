import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantUsuarioCajaAbiertaGet = async (arg: UserCredentialsType, merchantSucursalId: number) => {
    const body: AxiosPostBody = {
        "service": "MerchantUsuarioCajaAbiertaGet",
        "params": {
            merchantSucursalId: Number(merchantSucursalId)
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
            if(result?.status?.code === 0) {
                return [result.status]
            } else {
                return result.result;
            }
}