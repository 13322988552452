import React, { useEffect, useRef, useState } from 'react'
import { FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantSucursalCajaUpd } from '../../../../services/hooli-services/Merchants/MerchantSucursalCajaUpd.ts'
import { MerchantSucursalCajaGet } from '../../../../services/hooli-services/Merchants/MerchantSucursalCajaGet.ts'

export default function EditCajas({user, updateList, servicio, closeModal}) {

    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const formikRef = useRef();
    const getInfo = async(cred) => {
        const caja = await MerchantSucursalCajaGet(cred, cred.merchantId, user.merchantCajaId)
        console.log(caja)
        formikRef?.current?.setValues(caja[0]);
    }

    const handleConnection = async (values) => {
        const response = await MerchantSucursalCajaUpd(
            credentials,
            values,
        );
        console.log(response)
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Caja modificada correctamente",
                severity: "success",
                open: true,
            });
        }
    }

    useEffect(()=> {
        getInfo(credentials)
    },[])
    return (
        <Formik
        innerRef={formikRef}
        initialValues={{
            merchantCajaNombre: "",
            merchantCajaNumero: "",
            merchantSucursalNombre: "",
        }}
        validationSchema={
            yup.object({
                merchantCajaNombre: yup.string("Debe ingresar un nombre").required("Debe ingresar un nombre"),
                merchantCajaNumero: yup.number("Debe ingresar un número").required("Debe ingresar un número").typeError("Debe ingresar un número"),
            })
        }
        onSubmit={(values) => handleConnection(values)}
    >
        <Form className={styles.inputs} noValidate>
            <div className={styles["column-sm"]}>
                <>
                    <FormikTextInput
                        fullWidth={true}
                        name="merchantCajaNombre"
                        labelText="Nombre"
                    />
                    <FormikTextInput
                        fullWidth={true}
                        name="merchantCajaNumero"
                        labelText="Número"
                    />
                    <FormikTextInput
                        fullWidth={true}
                        name="merchantSucursalNombre"
                        labelText="Sucursal"
                        disabled={true}
                    />
                </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Modificar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <Spinner />}
                </div>
            </Form>
        </Formik>
    );
}