import React from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../services/Local-services';
// Assets
import Moneda from '../../../assets/icons/moneda.svg';
import HooliCoins from '../../../assets/icons/hc.svg';
import Hooli from '../../../assets/icons/hooli.svg';
import Credito from '../../../assets/icons/credito.svg';
// Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Styles
import classes from './Saldos.module.scss';

export default function Saldos({ cargando, totales }) {
    return (
        <div className={classes.saldos}>
            <div className={classes.titleContainer}>
                <img src={Moneda} alt="Moneda" />
                <h4 className={classes.title}>Saldos</h4>
            </div>
            <div className={classes.row}>
                <p className={classes.rowTitle}><img src={Hooli} alt="HC" /></p>
                <div className={`${classes.card} ${classes.total}`}>
                    <p>Total ARS</p>
                    <div className={classes.divider} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + (formatNumber(totales?.merchantSaldoARS) || "0,00")}</h4>
                </div>
                <div className={classes.card}>
                    <p>Ingresos del día</p>
                    <div className={classes.divider} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + (formatNumber(totales?.merchantIngresosARS) || "0,00")}</h4>
                </div>
                <div className={classes.card}>
                    <p>Egresos del día</p>
                    <div className={classes.divider} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + (formatNumber(totales?.merchantEgresosARS) || "0,00")}</h4>
                </div>
            </div>
            <div className={classes.row}>
            <p className={classes.rowTitle}><img src={Credito} alt="HC" width={"32px"}/></p>
                <div className={`${classes.card} ${classes.total}`}>
                    <p>Total Creditos</p>
                    <div className={classes.divider} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + (formatNumber(totales?.merchantSaldoCRRN) || "0,00")}</h4>
                </div>
                <div className={classes.card}>
                    <p>Ingresos del día</p>
                    <div className={classes.divider} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + (formatNumber(totales?.merchantIngresosCRRN) || "0,00")}</h4>
                </div>
                <div className={classes.card}>
                    <p>Egresos del día</p>
                    <div className={classes.divider} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + (formatNumber(totales?.merchantEgresosCRRN) || "0,00")}</h4>
                </div>
            </div>
            <button><Link to="/saldos">Ver detalle</Link></button>
        </div>
    )
};