import React, { useState } from 'react'
import styles from "./index.module.scss"
import Stepper from '../stepper/Stepper'
import CruzIcon from '../../assets/icons/cruz.png';
import ModalAltaComercio from '../alta-comercios/modal';
import { useSetRecoilState } from 'recoil';
import {userCredentials} from "../../recoilState/GlobalState"
import { useNavigate } from 'react-router-dom';
function Layaout({children, title, subtitle, isNeedStepper = false,numberStep, noPadding = false}) {
  const [isOpen,setIsOpen] = useState(false);
  const setCredentials = useSetRecoilState(userCredentials);
  const navigate = useNavigate()
  const handleClose = ()=> { 
    setIsOpen(true);
  }; 
  const handleClickModal = ()=> { 
    sessionStorage.setItem('hooliToken', "");
    sessionStorage.setItem('sessionId', "");
    sessionStorage.setItem('merchantId', "");
    sessionStorage.setItem('merchantRZ', "");
    sessionStorage.setItem('merchantCUIT', "");
    sessionStorage.setItem('rolCod',"");
    setCredentials({});
    return navigate("/login");
  }
  return (
    <section className={styles.page}>
    <article className={`${styles.container} ${noPadding ? styles.noPadding : ""}`}>
      {/* <div className={styles.close}> 
        <img src={CruzIcon} alt='x' width={"10px"} onClick={()=>handleClose()}/>
      </div> */}
      {
        isNeedStepper && 
        <div className={styles.stepper}> 
        {<Stepper numberStep={numberStep}/>}
        </div>
      }
    
     
         {
          title &&  <div className={styles.containerTitle}> 
          <h1 > {title} </h1>
           {subtitle &&  <p>{subtitle}</p>}
          <div className={styles.linear}></div>
      </div>
         } 
          
          
             {children}
    </article>
    <ModalAltaComercio isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} title="¿Estás seguro que queres salir?" text="Tené en cuenta que vas a tener que hacer todo el proceso de nuevo."/>
</section>
  )
}

export { Layaout

}