import React from 'react'
import styles from "../StylesGeneral.module.scss"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useEffect, useState } from "react";
import XGridServer from '../../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantSucursalGet} from "../../../services/hooli-services/Merchants/MerchantSucursalGet"
import HeaderButton from '../../../components/Button/HeaderButton';
import Modal from '../../../components/Modal/Modal';
import { ACTIONS } from '../../../consts/Actions';
import { AttachMoney, Cached, PointOfSale } from '@mui/icons-material';
import AddCobro from './Actions/AddCobro';
import CloseCaja from './Actions/CloseCaja';
import Filters from '../../../components/Filters';
import { useFormik } from 'formik';
import { merchantCajaGet, merchantUsuarioCajaIns } from '../../../services/hooli-services';
import {RNMerchantCobroGet} from "../../../services/hooli-services/Pagos/RNMerchantCobroGet.ts"
import { MerchantSucursalCajaGet } from '../../../services/hooli-services/Merchants/MerchantSucursalCajaGet.ts';
import { MerchantUsuarioCajaAbiertaGet } from '../../../services/hooli-services/Merchants/MerchantUsuarioCajaAbiertaGet.ts';
import { formatNumber } from '../../../services/Local-services.js';
import moment from 'moment';

const columns = [
    { field: 'cobroFH', headerName: 'Fecha y hora', flex: 1, minWidth: 100 },
    { field: 'cobroMonto', headerName: 'Monto', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'cobroNombre', headerName: 'Cliente', flex: 1, minWidth: 100 },
    { field: 'cobroTicket', headerName: 'Nro de factura', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
    { field: 'concepto', headerName: 'Concepto', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
    { field: 'estadoCobro', headerName: 'Estado', flex: 1, minWidth: 100 },
    { field: 'cobroFechaAcreditacion', headerName: 'Fecha de acreditación', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
];
const estados = {
    Z: "Rechazado",
    M: "Esperando confirmación del comercio",
    R: "Cancelado",
    A: "Autorizado",
    G: "No confirmado por comercio",
    V: "Conciliado",
    C: "Acreditado",
    P: "Pendiente de pago",
    X: "Cancelado"
};
export default function Cobros() {
    const [selectRow, setSelectRow] = useState({ status: false, row: {} })
    const [cobrosList, setCobrosList] = useState([])
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [page, setPage] = useState(1)
    const [cajasList, setCajasList] = useState([])
    const [sucursalesList, setSucursalesList] = useState([])
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [loading, setLoading] = useState(false)
    const [disabledCaja, setDisabledCaja] = useState(true)
    const [disabledSucursal, setDisabledSucursal] = useState(false)
    const formik = useFormik({
        initialValues: {
            cajaId: "",
            sucursalId: "",
        },
        onSubmit: () => {
            console.log(formik.values)
            // setResetTable((value) => !value);
        },
    })
    const getSucursales = async (cred) => {
        setLoading(true)
        const data = await MerchantSucursalGet(cred, cred.merchantId)
        if(data.status.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantSucursalId,
                sucursalId: data.merchantSucursalId,
                sucursalNombre: data.merchantSucursalNombre,
            }))
            setSucursalesList(formatList)
        }
        if(credentials.rolCod === "MJ"){
            formik.setFieldValue("sucursalId", credentials.merchantSucursalId)
            // setDisabledCaja(true)
            setDisabledSucursal(true)
        }
        if(credentials.rolCod === "ME"){
            if(credentials.merchantSucursalId !== null){
                formik.setFieldValue("sucursalId", credentials.merchantSucursalId)
                setDisabledSucursal(true)
            }
        }
        setLoading(false)
    }
    const getCajas = async (cred) => {
        setLoading(true)
        formik.setFieldValue("cajaId", "")
        if(formik?.values?.sucursalId){
            const response = await MerchantSucursalCajaGet(cred, cred.merchantId , null, formik.values.sucursalId)
            let formatList = response.map((data) => ({
                id: data.merchantCajaId,
                cajaId: data.merchantCajaId,
                cajaNombre: data.merchantCajaNombre,
                cajaStatus: data.merchantUsuarioCajaStatus,
            }))
            const cajaAbierta = await MerchantUsuarioCajaAbiertaGet(cred, formik.values.sucursalId)
            if(cajaAbierta[0]?.merchantSucursalCajaId){
                formik.setFieldValue("cajaId", cajaAbierta[0].merchantSucursalCajaId)
                getCobros(cred, cajaAbierta[0].merchantSucursalCajaId)
            } else {
                // setDisabledCaja(false)
            }
            setDisabledCaja(false)
            setCajasList(formatList)
        }
        setLoading(false)
    }
    const getCobros = async (cred, cajaId) => {
        setLoading(true)
        if(formik?.values?.cajaId || cajaId){
            if(!cajaId){
                const response = await merchantUsuarioCajaIns({...cred, merchantCajaId: formik?.values?.cajaId, merchantSucursalId: formik.values.sucursalId, status: "A"})
            }
        const data = await RNMerchantCobroGet(cred, {merchantSucursalId: formik.values.sucursalId, merchantCajaId: formik.values.cajaId}) 
        if(data.status.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantCobroId,
                concepto: data.merchantCobroConcepto,
                estadoCobro: estados[data.merchantCobroEstado],
                cobroFH: data.merchantCobroFH ? moment(data.merchantCobroFH).format('DD/MM/YYYY HH:mm:ss') : "",
                cobroFechaAcreditacion: data.merchantCobroFechaAcreditacion ? moment(data.merchantCobroFechaAcreditacion).format('DD/MM/YYYY') : "",
                cobroMedioPago: data.merchantCobroMedioPago,
                cobroMonto: formatNumber(data.merchantCobroMonto),
                cobroNombre: data.merchantCobroNombre,
                cobroDocumento: data.merchantCobroNroDeDocumento,
                cobroTicket: data.merchantCobroNumeroTicket,
                cobroOrigen: data.merchantCobroOrigen,
                cobroPresente: data.merchantCobroPresente,
                cobroTransaccion: data.merchantCobroTransaccion,
                cobroVersion: data.merchantCobroVersion,
                POSMontoDevolucion: data.merchantPOSMontoDevolucion,
            }))
            setCobrosList(formatList)
        }}
        setLoading(false)
    }
      const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    const handleCloseCaja = async() => {
        const response = await merchantUsuarioCajaIns({...credentials, merchantCajaId: formik?.values?.cajaId, merchantSucursalId: formik.values.sucursalId, status: "C"})
        formik.setFieldValue("cajaId", "")
        setCobrosList([])
    };
    useEffect(() => {
        setCobrosList([])
        getCajas(credentials)
    }, [formik?.values?.sucursalId])

    useEffect(() => {
        getCobros(credentials)
    }, [formik?.values?.cajaId])

    useEffect(() => {
        if (credentials.merchantId){
            getSucursales(credentials)
        }
        if(formik?.values?.cajaId){
            getCajas(credentials)
        }
    }, [credentials])

    useEffect(() => {
        if (openModal === false && credentials.merchantId){
            getSucursales(credentials)
        }
    }, [openModal])

  return (
    <>
        <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} /* close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} */ >
                {modalDetails.procede === ACTIONS.INSERT && (
                    <AddCobro
                        cajaId={formik?.values?.cajaId}
                        // servicio={service}
                        updateList={() => getCobros(credentials)}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
        <div className={styles.container}>
            <div className={styles.containerFilters} style={{ display: "flex", alignItems: "end", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "sucursalId", label: "Sucursal", disabled: disabledSucursal, options: sucursalesList, firtsOptionKey: "sucursalId", firstOptionName: "sucursalNombre", firstOptionValue: "sucursalId" },
                                { type: "select", name: "cajaId", label: "Caja", disabled: disabledCaja, options: cajasList, firtsOptionKey: "cajaId", firstOptionName: "cajaNombre", firstOptionValue: "cajaId" },
                            ]
                        }
                        disabled={loading}
                    />
                    <HeaderButton
                        text={"Cerrar caja"}
                        handleClick={handleCloseCaja}
                        data={{
                            title: "Cerrar caja",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!formik?.values?.cajaId}
                    >
                        <PointOfSale />
                    </HeaderButton>
                </div>
                <div className={styles.containerButtons}>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { getCobros(credentials) }}
                        disabled={selectRow.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nuevo cobro"}
                        handleClick={handleClick}
                        data={{
                            title: "Nuevo cobro",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={!formik?.values?.cajaId}
                    >
                        <AttachMoney />
                    </HeaderButton>
                </div>
            </div>
            <XGridServer loading={loading} columns={columns} setNumberPage={setPage} rowsCount={rowsCount} setPageSize={setPageSize} list={cobrosList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
        </div>
    </>
    )
}
