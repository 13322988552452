import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { formatNumber, transformDate } from '../../../services/Local-services';
// Assets
import Hooli from '../../../assets/icons/hooli.svg';
import Credito from '../../../assets/icons/credito.svg';
import ArrowDown from '../../../assets/icons/arrow-down-red.svg';
import ArrowDownWhite from '../../../assets/icons/arrow-down-white.svg';
// Styles
import classes from './Card.module.scss';
// Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// Charts
import { Chart, LineElement, Legend, Title, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

Chart.register(LineElement, Legend, Title, CategoryScale, LinearScale, PointElement);

export default function Card({ dataHoy, dataSerie, totales, link, title, image, position, cargando, ultimoMovimiento }) {
    const [open, setOpen] = useState(false);
    const [chart, setChart] = useState('ventas');

    const handleClick = (newChart) => () => {
        setChart(newChart);
        if (open && chart === newChart) return setOpen(false);
        setOpen(true);
    };

    const chartOptions = {
        responsive: true,
        // maintainAspectRatio: false,
        tension: 0.2,
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        scales: {
            yAxis: {
                ticks: {
                    stepSize: 1
                }
            }
        }
    };

    return (
        <>
        <div className={`${classes.card} ${classes[title.toLowerCase()]}`} style={position}>
            <div className={classes.titleContainer}>
                <img className={classes.icon} src={image} alt={title} />
                <h4 className={classes.title}>{title}</h4>
            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.row}>
                <div className={classes.total}>
                    <p>Total</p>
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales?.montoTotal ? '$' + formatNumber(totales?.montoTotal) : "$0,00"}</h4>
                    <div className={classes.divider}></div>
                    <div className={classes.horizontalDivider}>-</div>
                    <p>Operaciones</p>
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales?.cantidadTotal || 0}</h4>
                </div>
                <div className={classes.right}>
                <div className={`${classes.parcial} ${classes.credito}`}>
                    <img src={Credito} alt="Credito" />
                    <div className={classes.ventas}>
                        <p>Créditos</p>
                        <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales?.montoCreditos ? '$' + formatNumber(totales?.montoCreditos) : "$0,00"}</h4>
                        <div className={`${classes.operaciones} ${classes.purple}`}>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales?.cantidadCreditos || "0"}</h4>
                            <p>Operaciones</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.parcial} ${classes.hooli}`}>
                    <img src={Hooli} alt="Hooli" />
                    <div className={classes.ventas}>
                        <p>ARS</p>
                        <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales?.montoARS ? '$' + formatNumber(totales?.montoARS) : "$0,00"}</h4>
                        <div className={`${classes.operaciones} ${classes.blue}`}>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales?.cantidadARS || 0}</h4>
                            <p>Operaciones</p>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <button disabled={cargando}>
                        <Link to={link}>Ver detalle</Link>
                    </button>
                    <button disabled={cargando} onClick={handleClick('ventas')} className={open && chart === 'ventas' ? classes.selected : ''}>
                        <span>Ver gráfico de montos</span>
                        <img src={open && chart === 'ventas' ? ArrowDownWhite : ArrowDown} />
                    </button>
                    <button disabled={cargando} onClick={handleClick('operaciones')} className={open && chart === 'operaciones' ? classes.selected : ''}>
                        <span>Ver gráfico de operaciones</span>
                        <img src={open && chart === 'operaciones' ? ArrowDownWhite : ArrowDown} />
                    </button>
                </div>
            </div>
        </div>
                {open && chart === 'ventas' &&
                    <div className={classes.containerCharts}>
                        <div className={classes.charts}>
                            <div className={classes.containerTop}>
                                <h3>Montos</h3>
                                <CloseRoundedIcon onClick={() => setOpen(false)} />
                            </div>
                            <Line options={chartOptions} data={getChartData(dataSerie, 'monto')} />
                        </div>
                    </div>
                }
                {open && chart === 'operaciones' &&
                    <div className={classes.containerCharts}>
                        <div className={classes.charts}>
                        <div className={classes.containerTop}>
                                <h3>Operaciones</h3>
                                <CloseRoundedIcon onClick={() => setOpen(false)} />
                            </div>
                            <Line options={chartOptions} data={getChartData(dataSerie, 'operaciones')} />
                        </div>
                    </div>
                }
        </>
    )
};

const getChartData = (data, key) => {
    return {
        labels: Object.keys(data).map(item => moment(item).locale('es').format('dd DD/MM')),
        datasets: [
            {
                label: 'Total',
                data: Object.values(data).map(item => Object.values(item).reduce((acc, metodo) => acc + metodo[key], 0)),
                borderColor: 'rgb(229, 109, 161)',
                backgroundColor: 'rgba(229, 109, 161, 0.5)',
            },
            {
                label: 'Hooli',
                data: Object.values(data).map(item => item.H[key]),
                borderColor: 'rgb(85, 103, 174)',
                backgroundColor: 'rgba(85, 103, 174, 0.5)',
            },
            {
                label: 'Tarjeta Débito',
                data: Object.values(data).map(item => item.D[key]),
                borderColor: 'rgb(99, 209, 162)',
                backgroundColor: 'rgba(99, 209, 162, 0.5)',
            },
            {
                label: 'E-Transfer',
                data: Object.values(data).map(item => item.T[key]),
                borderColor: 'rgb(99, 195, 209)',
                backgroundColor: 'rgba(99, 195, 209, 0.5)',
            },
            {
                label: 'Tarjeta Crédito',
                data: Object.values(data).map(item => item.C[key]),
                borderColor: 'rgb(147, 115, 177)',
                backgroundColor: 'rgba(147, 115, 177, 0.5)',
            }
        ],
    };
}