import React, { useState } from 'react';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';

export function Apoderados({nextStep,setStepDocumentos}) {
    const [checked, setChecked] = useState(false)
    const [file,setFile] = useState([])
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const recoilAfipData = useRecoilValue(afipData);
    const [update, setUpdate] = useState([])
    const [comercio,setDataComercios] = useRecoilState(comercioData);
    const [disabledBtn, setDisabledBtn] = useState(false)
    // console.log(recoilAfipData);
    const urlDoc = async() => {
      setDisabledBtn(true)
        await Promise.all(file.map(async (archive, i) => {
            const url = await UrlDocumentosGet(credentials, `${archive.name}`, "APO" ,recoilAfipData.cuit);
            await sendFile(url.result[0].url, archive);
            setUpdate([...update, i])
        }));
        setDisabledBtn(false)
      // console.log(recoilAfipData.cuit.replaceAll('-', ''))
    }
    const sendFile = async (url, archive) => {
      // console.log(archive) 
      await DocumentosPut(url, archive)
    }

    const handleSubmit = async()=> {
      if(file.length !== 0){
        await urlDoc();
        setDataComercios({...comercio, documentosApoderadosCantidad: file.length});
        setStepDocumentos({
          step:11,
          title: "Beneficiarios finales",
          subtitle:"Informá los beneficiarios con una participación mayor al 10 %"
        })
      } else {
        setDataComercios({...comercio, documentosApoderadosCantidad: file.length});
        setStepDocumentos({
          step:11,
          title: "Beneficiarios finales",
          subtitle:"Informá los beneficiarios con una participación mayor al 10 %"
        })}
    }
  return (
   <> 
    <DropZone file={file} setFile={setFile} horizontal={true} update={update} optional={"No tengo Apoderados"} checked={checked} setChecked={setChecked}/>
    <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={file.length === 0 && !checked || disabledBtn}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepDocumentos({
           step:9, 
           title: "Acta de aprobación de balance",
           subtitle: "Subí tu Acta de aprobación de balance"
      })}/>
        </Box>
   </>
        
    
  )
}

 
