import React from 'react'
import styles from "../StylesGeneral.module.scss"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useEffect, useState } from "react";
import XGridServer from '../../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantEmpleadoGet} from "../../../services/hooli-services/Merchants/MerchantEmpleadosGet"
import HeaderButton from '../../../components/Button/HeaderButton';
import Modal from '../../../components/Modal/Modal';
import EditEmpleados from './Actions/EditEmpleados';
import AddEmpleados from './Actions/AddEmpleados';
import { ACTIONS } from '../../../consts/Actions';
import { Add, Cached, EditOutlined } from '@mui/icons-material';
import DelEmpleados from './Actions/DelEmpleados';
import { formatDoc } from '../../../services/Local-services';

export default function Empleados() {
    const [selectRow, setSelectRow] = useState({ status: false, row: {} })
    const [empleadosList, setEmpleadosList] = useState([])
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [page, setPage] = useState(1)
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const columns = [
        { field: 'usuarioNombreCompleto', headerName: 'Nombre y apellido', flex: 1, minWidth: 100 },
        { field: 'usuarioNroDeDocumentoFormateado', headerName: 'DNI', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
        { field: 'merchantSucursalNombre', headerName: 'Sucursal', flex: 1, minWidth: 100 },
        { field: 'rolNombre', headerName: 'Rol', flex: 1, minWidth: 100 },
    ];
    
    const getEmpleados = async (cred) => {
        const data = await MerchantEmpleadoGet(cred, cred.merchantId)
        if(data.status.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantUsuarioId,
                imagePathPERFIL: data.imagePathPERFIL,
                merchantCUIT: data.merchantCUIT,
                merchantId: data.merchantId,
                merchantNombre: data.merchantNombre,
                merchantSucursalId: data.merchantSucursalId,
                merchantSucursalNombre: data.merchantSucursalNombre,
                merchantUsuarioFecha: data.merchantUsuarioFecha,
                merchantUsuarioId: data.merchantUsuarioId,
                rolCod: data.rolCod,
                rolId: data.rolId,
                rolNombre: data.rolNombre,
                usuarioApellidos: data.usuarioApellidos,
                usuarioCelular: data.usuarioCelular,
                usuarioEmpleadoId: data.usuarioEmpleadoId,
                usuarioMail: data.usuarioMail,
                usuarioNombres: data.usuarioNombres,
                usuarioNroDeDocumento: data.usuarioNroDeDocumento,
                usuarioNroDeDocumentoFormateado: formatDoc(data.usuarioNroDeDocumento),
                usuarioNombreCompleto: `${data.usuarioNombres} ${data.usuarioApellidos}`,
            }))
            setEmpleadosList(formatList)
        }
    }
      const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    useEffect(() => {
        if (credentials.merchantId){
            getEmpleados(credentials)
        }
    }, [credentials])
    useEffect(() => {
        if (openModal === false && credentials.merchantId){
            getEmpleados(credentials)
        }
    }, [openModal])
  return (
    <>
    <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} /* close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} */ >
            {modalDetails.procede === ACTIONS.INSERT && (
                <AddEmpleados
                    user={selectRow.row}
                    // servicio={service}
                    updateList={() => getEmpleados(credentials)}
                    closeModal={closeModal}
                />
            )}
            {modalDetails.procede === ACTIONS.UPDATE && (
                <EditEmpleados
                    user={selectRow.row}
                    // roles={connectionRoles}
                    updateList={() => getEmpleados(credentials)}
                    closeModal={closeModal}
                />
            )}
            {modalDetails.procede === ACTIONS.DELETE && (
                <DelEmpleados
                    user={selectRow.row}
                    // servicio={service}
                    updateList={() => getEmpleados(credentials)}
                    closeModal={closeModal}
                />
            )}
        </Modal>
    <div className={styles.container}>
        <div className={styles.containerFilters}>
            <div className={styles.containerButtons}>
                <HeaderButton
                    text={"Actualizar"}
                    onClick={() => { getEmpleados(credentials) }}
                    disabled={selectRow.row.id || false}
                >
                    <Cached />
                </HeaderButton>
                <HeaderButton
                    text={"Nuevo empleado"}
                    handleClick={handleClick}
                    data={{
                        title: "Nuevo empleado",
                        procede: ACTIONS.INSERT
                    }}
                    disabled={selectRow.row.id}
                >
                    <Add />
                </HeaderButton>
                <HeaderButton
                    text={"Modificar empleado"}
                    handleClick={handleClick}
                    data={{
                        title: "Modificar empleado",
                        procede: ACTIONS.UPDATE
                    }}
                    disabled={!selectRow.row.id}
                >
                    <EditOutlined />
                </HeaderButton>
                <HeaderButton
                    text={"Eliminar empleado"}
                    handleClick={handleClick}
                    data={{
                        title: "Eliminar empleado",
                        procede: ACTIONS.DELETE
                    }}
                    disabled={!selectRow.row.id}
                >
                    <DeleteOutlineRoundedIcon />
                </HeaderButton>
            </div>
        </div>
        <XGridServer columns={columns} setNumberPage={setPage} rowsCount={rowsCount} setPageSize={setPageSize} list={empleadosList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
    </div>
</>
    )
}
